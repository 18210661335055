<template>
    <div class="content-page">
        <div class="container-fluid add-form-list">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header d-flex justify-content-between">
                            <div class="header-title">
                                <h4 class="card-title">Edit a Meal type </h4>
                            </div>
                        </div>
                        <div class="card-body">
                            <form action="page-list-category.html" @submit.prevent="store" data-toggle="validator">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Title*</label>
                                            <input type="text" v-model="type.libelle" class="form-control" placeholder="Type here" required>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Extra price*</label>
                                            <input type="text" v-model="type.cout_extra" class="form-control" placeholder="Type here" required>
                                        </div>
                                    </div>                             
                                </div> 

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Sell price *</label>
                                            <input type="number" v-model="type.cout" class="form-control" placeholder="Enter coût" required>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>  
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>staff price *</label>
                                            <input type="number" v-model="type.cout_staff" class="form-control" placeholder="Enter coût" required>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div> 
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Buy price *</label>
                                            <input type="number" v-model="type.buy_price" class="form-control" placeholder="Enter coût" required>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div> 
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Take away price *</label>
                                            <input type="number" v-model="type.cout_away" class="form-control" placeholder="Enter coût" required>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>      
                                </div> 
                                      
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Start hour *</label>
                                            <input type="time" v-model="type.hour_start" class="form-control" placeholder="Type here" required>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>End hour *</label>
                                            <input type="time" v-model="type.hour_end" class="form-control" placeholder="Enter coût" required>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>                                 
                                </div>                     
                                <button type="submit" class="btn btn-primary mr-2">Save</button>
                                <button type="reset" class="btn btn-danger">reinitialiser</button>
                            </form>
                        </div>
                    </div>
                    <div class="vld-parent">
                        <loading :active.sync="isLoading" 
                        :can-cancel="true" 
                        :is-full-page="fullPage"></loading>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {URL_API} from '@/config'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import Swal from 'sweetalert2'
export default {
    components: {
            Loading
    },
    data() {
        return {permissions: [],
            type:{},
            isLoading: false,
            fullPage: true
        };
    },
    mounted(){this.$store.state.user.permissions.forEach(element => {
            this.permissions.push(element.name)
        });
        this.getType()
    },

    methods:{
        getType() {
            this.isLoading = true
            axios.get(URL_API+'types-repas/'+this.$route.params.id)
            .then(response => {
                
                console.log(response)
                
                if (response.data.status) {
                    
                    this.type = response.data.data
                this.isLoading = false
                }
            })
            .catch(error => {
                console.log(error)
                this.errored = true
                this.isLoading = false
            })
        },
        store() {
            axios.put(URL_API+'types-repas/'+this.$route.params.id, this.type)
            .then(response => {
                console.log('re', response.data)
                
                if (response.data.status == true) {
                
                    Swal.fire(
                        'Edited!',
                        'The Meal type  was edited successfully..',
                        'success'
                    )
                    this.$router.push('/index-types-repas')
                     
                }
                else {
                    Swal.fire(
                        'Warning!',
                            response.data.data,
                        'warning'
                    )
                }
            })
            .catch(error => {
                console.log(error)
                this.errored = true
                this.isLoading = false
            })
        },
    }
}
</script>