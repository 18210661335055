<template>
    <div class="content-page">
        <div class="container-fluid add-form-list">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header d-flex justify-content-between">
                            <div class="header-title">
                                <h4 class="card-title">Scan a QR code</h4>
                            </div>
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="send">
                                <div class="row">
                                     
                                    <div class="col-md-12">
                                        <div class="form-group col-md-12">
                                            <label>Code *</label>
                                            <input v-model="payload.code" id="code" type="password" class="form-control"  required>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label>Caterer *</label>
                                            <div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio" v-model="payload.caterer" name="cat" :value="1"> Yes
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio" v-model="payload.caterer" name="cat" :value="0"> No  
                                            </div>
                                            <!-- <label>Takeaway *</label>
                                            <div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio" v-model="payload.takeaway" name="away" :value="1"> Yes
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio" v-model="payload.takeaway" name="away" :value="0"> No  
                                            </div> -->
                                            <div class="help-block with-errors"></div>
                                            <label v-if="types.length > 0">Supplement *</label>
                                            <div v-if="types.length > 0">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio" v-model="payload.supplement" name="supp" :value="1"> Yes
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio" v-model="payload.supplement" name="supp" :value="0"> No  
                                            </div>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>                                 
                                </div>                            
                                <!-- <button type="submit" class="btn btn-primary mr-2">Save</button>
                                <button type="reset" class="btn btn-danger">reinitialiser</button> -->
                            </form>
                        </div>
                    </div>
                    
                </div>
            </div>
            <!-- Page end  -->
        </div>
        <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="true" 
                :is-full-page="fullPage"></loading>
            </div>
    </div>
      
</template>
<script>
import axios from 'axios'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import {URL_API} from '@/config'
import Swal from 'sweetalert2'
export default {
    components: {
            Loading
    },
    data(){
        return{
            payload: {
                code : '',
                takeaway: 0,
                supplement: 0,
                caterer: 0
            },
            isLoading: false,
            fullPage: true,
            request :0,
            timer:null,
            types: {}
        }
    },
    methods: {
        getTypes(){
            let app = this
            app.isLoading = true
            axios.get(URL_API+'types-repas-admin')
            .then(function (reponse){
                console.log('reponse', reponse)
                if (reponse.data.status) {
                    const currentTime = new Date().toTimeString().split(" ")[0];
                    const allowedTypes = ["Lunch", "Dinner"];
                    const filteredTypes = reponse.data.data.filter(tp => allowedTypes.includes(tp.libelle));
                    const availableTypes = filteredTypes.filter(tp => {
                        return currentTime >= tp.hour_start && currentTime <= tp.hour_end;
                    });
                    console.log(availableTypes);
                    app.types = availableTypes
                    app.isLoading = false
                }
            })
            .catch(function (error){
                console.log('error', error);
            })
        },
        onDecode () {
            const app = this
            app.isLoading = true
            
            app.request = 1
            axios.post(URL_API+'urgency-ticket', app.payload)
            .then(function (reponse){
                app.isLoading = false
                console.log('reponse', reponse);
                if (reponse.data.status == true) {
                    app.request = 1
                    Swal.fire('Successful', reponse.data.message, 'success')
                }else{
                    Swal.fire('Failure', reponse.data.message, 'error')
                }
                app.payload.code = ''
                document.getElementById("code").focus();
               app.request = 0
               //clearInterval(this.timer)

            })
            .catch(function (error){
                console.log('error', error);
                app.request = 0
            })
        },
        send() {
            setTimeout(() => {
            this.onDecode()
            }, 2000);
        },
    },
    mounted() {
        document.getElementById("code").focus();
        this.getTypes()
    },
}
</script>
<style>
.qr{
    height: 150px;
    width: 150px;
    margin: auto;
}
</style>